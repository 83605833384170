import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import './App.scss';
import Loader from './components/Loader';
import { API_URL, GET_ALL_PAGE_PASSWORD } from './config';
import routes from './routes/routeModel';
import {
  checkValidToken,
  checkWishlist,
  CONFIG_VARS,
  fetchClient,
  getUserLocation,
} from './redux/Helpers';
import ScrollToTop from './redux/Helpers/ScrollToTop';
import { Redirect, RouteProps } from 'react-router';
import RouteChangeTracker from './components/RouteChangeTracker';
import ReactGA from 'react-ga';
import Header from './components/Header';
import { useDispatch } from 'react-redux';
import { loginSuccessActions, getUserLocationActions } from './redux/auth/actions';
import { getSiteSettings } from './redux/siteSettings/actions';
import useGlobalConfig from './utils/customHooks/useGlobalConfig';
import CommonHelmet from './components/CommonHelmet';
//import Whatsapp from './components/Whatsapp';
import CookieModal from './components/CookieModal';
import SiteUnderMaintenance from './components/SiteUnderMaintenance';
import Footer from './components/Footer';
import useFixedBlockIcon from './utils/customHooks/useFixedBlockIcon';
import { setFormInputPw } from './redux/formInputPw/actions';

const PaymentVerifyPage = lazy(() => import('./containers/PaymentVerifyPage'));
const OrderCODVerifyPage = lazy(() => import('./containers/OrderCODVerifyPage'));
const AccountPage = lazy(() => import('./containers/AccountPage'));
const RenderPage = lazy(() => import('./containers/RenderPage'));
const Notfound = lazy(() => import('./components/Notfound'));
const BlockText = lazy(() => import('./components/BlockText'));

console.log('API URL', API_URL); // use this as api base url, this line should probably be erased later.

ReactGA.initialize('UA-89663334-1', {
  debug: false,
});

// ReactGA.ga('require', 'ecommerce');

export interface IPrivateRouteProps extends RouteProps {
  isAuth: boolean; // is authenticate route
  redirectPath: string; // redirect path if don't authenticate route
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = (props) => {
  return props.isAuth ? (
    <Route {...props} component={props.component} render={undefined} />
  ) : (
    <>
      {props?.location?.pathname === '/account/my-reviews' ? (
        <Redirect to={{ pathname: '/', search: '?redirect=/account/my-reviews' }} />
      ) : (
        <Redirect to={{ pathname: props.redirectPath }} />
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
const App: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const [siteUnderMaintenance] = useGlobalConfig();
  useFixedBlockIcon();

  useEffect(() => {
    const setUser = async () => {
      const user = await checkValidToken();
      dispatch(loginSuccessActions(user?.data));
    };
    checkWishlist();
    setUser();
  }, [location.pathname]);

  useEffect(() => {
    const url = `${GET_ALL_PAGE_PASSWORD}`;
    const options = {
      method: 'GET',
      url: url,
      body: null,
    };

    fetchClient(options).then((res) => {
      if (res?.success) {
        dispatch(setFormInputPw(res.data));
      } else if (res.isError) {
        dispatch(setFormInputPw([]));
        console.log('res.isError: ', res.isError);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    const setConfigVars = () => {
      dispatch(getSiteSettings(CONFIG_VARS));
    };
    setConfigVars();
  }, []);

  useEffect(() => {
    const setUserLocation = async () => {
      const token = localStorage.getItem('sundoraToken');
      if (token) {
        dispatch(getUserLocationActions('BD'));
      } else {
        const userLocation = await getUserLocation();
        dispatch(getUserLocationActions(userLocation));
      }
    };
    setUserLocation();
  }, []);

  return (
    <div>
      <CookieModal />
      <BrowserRouter>
        {/*<Whatsapp />*/}
        <CommonHelmet />
        {siteUnderMaintenance ? (
          <SiteUnderMaintenance />
        ) : (
          <>
            <Suspense fallback={<></>}>
              <Header />
            </Suspense>
            <Suspense fallback={<Loader />}>
              <BlockText />
              <ScrollToTop>
                <Switch>
                  {routes.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={(props: RouteComponentProps<any>) => (
                          <route.component {...props} {...route.props} name={route.name} />
                        )}
                      />
                    );
                  })}
                  <PrivateRoute
                    isAuth={localStorage.getItem('sundoraToken') ? true : false}
                    redirectPath="/404"
                    path="/account/:tabName"
                    component={AccountPage}
                  />
                  <PrivateRoute
                    isAuth={localStorage.getItem('sundoraToken') ? true : false}
                    redirectPath="/404"
                    path="/verify/:status/:id"
                    component={PaymentVerifyPage}
                  />
                  <Route path="/order-verify/:status/:id" component={OrderCODVerifyPage} />
                  <Route path="/" exact={false} component={RenderPage} />
                  <Route component={Notfound} />
                </Switch>
              </ScrollToTop>
              <RouteChangeTracker />
              {/* {!isMobile && <BlockFBMessenger />} */}
              <Footer />
            </Suspense>
          </>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
