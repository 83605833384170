import { FORM_INPUT_PW } from './actions';

const initialState = {
  payload: [],
};

export default function formInputPwReducer(state = initialState, action: any) {
  switch (action.type) {
    case FORM_INPUT_PW:
      return { payload: action.payload };
    default:
      return state;
  }
}
